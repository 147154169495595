.navbar {
    height: fit-content;
    min-height:3em;
    /*background-color: #111111;*/
    position: relative;
    font-family: 'Bolton';
    margin-bottom:-1em;
}

.navbar .container {
    /*    max-width: 1100px;*/
    margin: 0 auto;
    padding: 0 2em;
    display: flex;
    justify-content: space-between;
    /*align-items: center;*/
    height: 100%;
    float: right;
}

.navbar .Hamburger {
    content: url("../images/hamburger.png");
    margin-top: 1em;
    border: 0;
    height:2em;
}

.navbar .NavConnectButton
{
    width:20em;
    margin:.5em;
    height:3em;
}

.navbar .HeaderSmall
{
    margin:.5em;
    cursor:pointer;
    height:3em;
}

.menu-icon {
    display: none;
}

.nav-elements {}

.nav-elements ul {
    display: flex;
    justify-content: space-between;
    list-style-type: none;
}

.nav-elements ul li:not(:last-child) {
    margin-right: 2em;
}

.nav-elements ul a {
    font-size: 1em;
    font-weight: 400;
    color: #ffffff;
    text-decoration: none;
}

.nav-elements ul a.active {
    color: #b0f6ff;
    font-weight: 500;
    position: relative;
}

.nav-elements ul a:hover {
    color: #c7d3fc;
    transition: all 0.3s ease-in;
}

/*
  .nav-elements ul a.active::after {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #574c4c;
  }
  */

  /*
@media (max-width: 768px) {
    .nav-elements ul li:not(:last-child) {
        margin-right: 30px;
    }
}
*/

@media (max-width: 1024px) {
    .menu-icon {
        display: block;
        cursor: pointer;
    }

    .nav-elements {
        position: absolute;
        right: 0;
        top: 3em;
        background-color: #000000c5;
        width: 0px;
        /* height: calc(100vh - 60px); */
        transition: all 0.3s ease-in;
        overflow: hidden;
        z-index:100;
        font-size:1.5em;
        white-space: nowrap;
    }

    .nav-elements.active {
        width: 15em;
    }

    .nav-elements ul {
        display: flex;
        flex-direction: column;
    }

    .nav-elements ul li {
        margin-right: unset;
        margin: .5em;
    }
}

