.CollectionsHeader
{
    font-size: 1.5em;
    flex-basis: 100%;
    text-align: center;
    font-weight: bold;
    margin:1em 1em .5em 1em;
    font-family: 'Bolton';
  }

.CollectionsContainer
{
    display:flex;
    gap:1em;    
    width:100%;
    height:fit-content;
    flex-wrap: wrap;
    cursor:pointer
}

.CollectionItemOuter
{
    display:flex;
    flex-grow: 1;
}

.CollectionItem
{
    border-image-source: url('../../images/BorderSmallSquare.png');  
    border-image-repeat: stretch;
    border-image-slice: 32 fill;    
    box-sizing: content-box;
    border-image-width: 2em;
    width: 12em;
    padding: 1em 1em 5em 1em;
    margin: 0 auto;
    position: relative;
}

.CollectionItemImage
{
    border:0;
    width:100%;
}

.CollectionItemVideoThumb
{
    position:absolute;
    top:0;
    left:0;
    width:100%;
    pointer-events:none;
}

.CollectionItemName
{
    font-size: 1.25em;
    color:var(--item-name-color);
    margin-bottom: 1em;
    font-weight: bolder;
}

.CollectionItemDescription
{
    margin-bottom:1em;
}

.CollectionItemOwned
{
    font-size:.8em;
    margin-top:-1em;
    margin-bottom:1em;
}

.CollectionItemButton
{
    border-image-source: url("../../images/store_buttonSM.png");
    border-image-slice: 34 96 43 74 fill;
    border-image-width: 1.2em 4em 1.5em 3em;
    background: #fff0;
    height: 4em;
    font-size: 1em;
    padding:0 3em .4em 3em;
    color: var(--button-text-color);
    position: absolute;
    left: 3em;
    bottom:1em;
    cursor:pointer;
}

.CollectionItemButtonText
{
    font-size: 1.25em;
}

.InventoryListHeader
{
    font-size: 1.5em;
    flex-basis: 100%;
    text-align: center;
    font-weight: bold;
    margin:-.5em 1em .5em 1em;
    font-family: 'Bolton';
  }

  .InventoryListCount
  {
    color: var(--text-color);
  }

  .InventoryListCollectionName
  {
      color:var(--item-name-color);
  }

  .InventoryListContainer
{
    display:flex;
    gap:1em;    
    width:100%;
    height:fit-content;
    flex-wrap: wrap;
    cursor:pointer;
    justify-content: space-evenly;
}

.InventoryListItemOuter
{
    display:flex;
    /*flex-grow: 1;*/
}

.InventoryListItem
{
    border-image-source: url('../../images/BorderSmallSquare.png');  
    border-image-repeat: stretch;
    border-image-slice: 32 fill;    
    box-sizing: content-box;
    border-image-width: 2em;
    width: 12em;
    padding: 1em;
    margin: 0 auto;
    position:relative;
}

.InventoryListItemImageContainer
{
    position:relative;
    top:0;
    left:0;
}

.InventoryListItemImage
{
    border:0;
    width:100%;
}

.InventoryListItemName
{
    font-size: 1.25em;
    color:var(--item-name-color);
    font-weight: bolder;
}

.InventoryListItemDescription
{
    margin-bottom:1em;
}

.InventoryListItemInfo
{
    content:url("../../images/info_tiny.png");
    border:0;
    width:1em;
    position: absolute;
    bottom:1em;
    right:1em;
}

.InventoryListItemChatButton
{
    position:absolute;
    top:.75em;
    right:.5em;
    cursor:pointer;
    background:none;
    border:none;
    width:3em;
    font-size:1em;
}
.InventoryListItemChatButton img
{
    width:100%;
    border:none;
}

.PlayButton
{
    content:url("../../images/playbutton.png");
    position:absolute;
    top:0;
    left:0;
    bottom:0;
    right:0;
    margin:auto;
    width:25%;
}

.InventoryItemHeader
{
    width: 100%;
    border: 2px solid var(--nft-name-border-color);
    padding: .5em 1em;
    font-size: 1.25em;
    font-weight:bold;
    margin-top:0;
    display: inline-block;
    box-sizing: border-box;
    border-image:var(--green-gradient-border);    
}

.InventoryItemName {
    float:left;
    color:var(--item-name-color);    
}
.InventoryItemID {
    color:var(--item-name-color);
}
.InventoryItemIDLabel {
    float:right;
    color:var(--text-color);
}

.InventoryButtonBar
{
    display:grid;
    gap:1em;    
    width:100%;
    height:fit-content;
    grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
    margin-top: 1em;
}

.InventoryButton
{
    border-image-source: url("../../images/store_buttonSM.png");
    border-image-slice: 34 96 43 74 fill;
    border-image-width: 1.2em 4em 1.5em 3em;
    background: #fff0;
    height: 4em;
    font-size: 1em;
    padding:0 2em .4em 2em;
    color: var(--button-text-color);
    cursor: pointer;
}

.InventoryButton:disabled
{
    filter: none;
    -webkit-filter: grayscale(100%);
    -moz-filter:    grayscale(100%);
    -ms-filter:     grayscale(100%);
    -o-filter:      grayscale(100%);    
}

.InventoryChatButton
{
    border-image-source: url("../../images/store_buttonSM.png");
    border-image-slice: 34 96 43 74 fill;
    border-image-width: 1.2em 4em 1.5em 3em;
    background: #fff0;
    width:40%;
    height: 4em;
    font-size: 1em;
    padding:0 2em .4em 2em;
    color: var(--button-text-color);
    cursor: pointer;
    margin-left:0 auto;
    min-width:12em;
}

.InventoryChatButtonIcon
{
    height:1.75em;
    width:auto !important;
    position:relative;
    top:.2em;
    margin-right:1em;
}

.InventoryChatButton .ButtonText
{
    position:relative;
    top:-.1em;
    font-size:1.5em
}