:root
{
    --item-name-color:#ebb90f;
    --text-color:#eadeb4;
    --link-normal-color:#73b870;
    --link-hover-color:#99f695;
    --raffle-status-color:rgba(92, 255, 100, 0.719);
    --body-background-color:#090e08;
    --button-text-color:#ffffff;
    --alert-color:#ff2500cf;
    --nft-property-border-color:#7da380;
    --nft-property-background-color:#253c25;
    --nft-property-label-color:#54ddb0;
    --nft-property-value-color:#eadeb4;
    --nft-property-percent-color:#b1ab91;
    --nft-name-border-color:#7da380;
    --daily-reward-highlight-color:#532457;
    --daily-reward-highlight-color-darker:#231024;
    --daily-reward-border-color:#7da380;
    --daily-reward-border-highlight-color:#a146aa;
    --daily-reward-label-highlight-color:#54ddb0;
    --collapsible-background-color:#3b603f;
    --markdown-bold-color:#ffe083;
    --questbox-border-color:#7b959d;
    --questbox-background-color:#08495e;
    --green-gradient-border:linear-gradient(to bottom right, #7da380, #365a39) 2;
    --yellow-gradient-border: linear-gradient(to bottom right, #b3a34d, #c48a1f) 2;  
    --art-deco-box-color: #c0a561;
}

@font-face {
    font-family: 'AlanFont';
    src: url('./fonts/ALANfontRegular.ttf') format('truetype');
}

@font-face {
    font-family: 'Bolton';
    src: url('./fonts/Bolton.ttf') format('truetype');
}

html {
    height: 100%;
    width: 100%;
    color: var(--text-color);
}

body {
    background-image: url('./images/background.jpg'), url('./images/Symbol1.png'), url('./images/Symbol2.png'), url('./images/Symbol3.png'), url('./images/Symbol4.png'), url('./images/Symbol5.png'), url('./images/Symbol6.png'), url('./images/Symbol7.png'), url('./images/Symbol8.png'), url('./images/Symbol9.png'), url('./images/Symbol10.png');
    background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, no-repeat, no-repeat, no-repeat, no-repeat, no-repeat, no-repeat, no-repeat;
    background-position: center top, 40% 59%, 90% 50%, 95% 93%, 10% 80%, 20% 45%, 45% 82%, 5% 27%, 73% 65%, 80% 78%, 18% 99%;
    background-size: 100% auto, auto 10%, auto 10%, auto 10%, auto 10%, auto 6%, auto 6%, auto 6%, auto 6%, auto 6%, auto 6%;
    background-color: var(--body-background-color);
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
}

body.CurseBreaker {
    background-image: url('./images/curseBreakerHome_BKG_V1.jpg'), url('./images/Symbol1.png'), url('./images/Symbol2.png'), url('./images/Symbol3.png'), url('./images/Symbol4.png'), url('./images/Symbol5.png'), url('./images/Symbol6.png'), url('./images/Symbol7.png'), url('./images/Symbol8.png'), url('./images/Symbol9.png'), url('./images/Symbol10.png');
}

button
{
    outline:none;
}
/*
button:focus
{
    color:#ffff00;
}
*/

/* Inserting this collapsed row between two flex items will make 
 * the flex item that comes after it break to a new row */
.break {
    flex-basis: 100%;
    height: 0;
}
  
.header {
    width:100%;
    max-width:40em;
    margin:1em 0;
}

.headerSpacer {
    height: 16vh;
    width: 100%;
    display: block;
}

.headerTextTight
{
    width:100%;
    font-family: 'Bolton', san-serif;
    font-size:1.5em;
    margin:auto;
    line-height: 1.6;    
}

.headerText {
    width: 50vw;
    display: block;
    text-align: center;
    font-family: 'Bolton', san-serif;
    font-size:1.5em;
    margin:auto;
    line-height: 1.6;    
}

.flexContainer {
    display:flex;
    flex-direction:column;
    min-height:100vh;
}

.contentDiv {
    width: 100%;
    /*content: 65vh;*/
    display: flex;
    flex-grow: 1;
    /*flex-direction: column;*/
    /*filter:drop-shadow(1em 1em .2em #00000082);*/
}

.contentFitDiv {
    display:flex;
    margin:auto;
    flex-wrap:wrap-reverse;
    gap:1em;
}
.videoDiv {
    margin:auto;
    filter:drop-shadow(1em 1em .2em #00000082);
}

.videoWrapper {
    display: grid;
    text-align: center;
    grid-template-columns: auto auto;
    gap: 2em 2em;
    margin-left: 2em;
}

.videoWrapper video {
    margin:auto;
}

.dialogWrapper {
    margin:0 auto;
    /*width:100%;*/
    max-width:1600px;
    position: relative;
}

.dialogWrapperWideCentered {
    margin:auto;
}

.dialog {
    max-width: 1600px;
    margin:1em;
    vertical-align: middle;
    /*background-color: rgba(0,0,0,0.5);*/
    position:relative;
}

.ChatTutorialFrame
{
    border: 1em solid transparent;
    border-image-source: url('./images/BigBorderNew.png');  
    border-image-repeat: stretch;
    border-image-slice: 16;    
    position:absolute;
    top: -0.25em;
    left: -0.25em;
    width: calc(100% + .5em);
    height: calc(100% + .5em);
    box-sizing: border-box;
    pointer-events: none;    
}

.dialogFrame
{
    pointer-events: none;    
}

.centerContent
{
    display:flex;
    align-items:center;
    flex-direction:column;
/*   max-width:50vw;*/
    margin-bottom: 1em;
}

.ArtDecoBorder
{
  border:.125em solid var(--art-deco-box-color);
  border-image: var(--yellow-gradient-border);  
  pointer-events:none;
}

.ArtDecoBorder:before {
  content:'';
  position: absolute;
  top:-.5em;
  bottom:-.5em;
  left:.3em;
  right:.3em;
  border-left: .125em solid var(--art-deco-box-color);
  border-right: .125em solid var(--art-deco-box-color);
	border-image: var(--yellow-gradient-border);
}

.ArtDecoBorder:after {
  content:'';
  position: absolute;
  top:.3em;
  bottom:.3em;
  left:-.5em;
  right:-.5em;
  border-top: .125em solid var(--art-deco-box-color);
  border-bottom: .125em solid var(--art-deco-box-color);
	border-image: var(--yellow-gradient-border);
}



.dialogDiv {
    display: block;
/*    padding: 1em 2em; */
}

.dialogBtnDiv {
    display: block;
    padding-top: 0;
    text-align: center;
}

.dialogText {
    text-align: left;
    font-family: 'AlanFont', san-serif;
    font-size:1em;
}

.dialogTextRed {
    text-align: left;
    font-family: 'AlanFont', san-serif;
    font-size:1em;
    color: var(--alert-color);
}

.redalert 
{
    color: var(--alert-color);
}
.redalert::before
{
    content:url("./images/warning.png");    
    position:relative;
    top:.15em;
    margin-right:.2em;
    animation: Pulsate 2s linear infinite;
}
@keyframes Pulsate {
    from { opacity: 1; }
    50% { opacity: .5; }
    to { opacity: 1; }
}

.yellow
{
    color: var(--item-name-color);
}

.pointer {
    cursor:pointer;
}

.dialogTextCentered {
    text-align: center;
    font-family: 'AlanFont', san-serif;
    font-size:1em;
}

.dialogBtn {
    padding-top: 1em;
    padding-bottom: 1.5em;
    padding-left: 4em;
    padding-right: 4.5em;
    margin-left:0.4em;
    border: none;
    display: inline-block;
    font-family: 'AlanFont', san-serif;
    font-size: 1.5em;
    color: var(--button-text-color);
    background-color: transparent;
    background-image: url("./images/store_buttonSM.png");
    background-repeat: no-repeat;
    background-position: top left;
    background-size: 100% 100%;
}

p {
    color: rgb(241, 228, 185);
    position:relative;
}

a:link, a:visited {
    color: var(--link-normal-color);
}

a:hover, a:active {
    color: var(--link-hover-color);
}

a img {
    border:0;
}

.footer {
    width: 100%;
    height: 7vh;
    margin-bottom: 0;
    padding: 0;
    display: block;
    text-align: center;
    margin-top:1em;
    position:relative;
}

.footerText {
    font-family: 'AlanFont', san-serif;
    font-size:.75em;
}
.footerSocial {
    margin:0 1em;
    height:2em;
}

.footerVersion
{
    position:absolute;
    right:1em;
    bottom:0;
}

.instructionsPage li:not(:last-child) {
    margin-bottom: 1em;
}

.clockContainer
{
    display: flex;
    justify-content: center;
}

form  { display: table; border-spacing:.3em; text-align:center;position:relative}
form p     { display: table-row;  }
form label { text-align: right; display: table-cell; font-size: 1em; }
form input[type=password] { display: table-cell; margin-left:.2em; font-size: 1em; width:15em}
form input[type=text] { display: table-cell; margin-left:.2em; font-size: 1em; width:15em}
form button {
    margin-top:.5em;
    cursor:pointer;
}
form button:disabled {
    filter: none;
    -webkit-filter: grayscale(100%);
    -moz-filter:    grayscale(100%);
    -ms-filter:     grayscale(100%);
    -o-filter:      grayscale(100%);    
}

#gameIDDesc {
    font-size:.8em;
    position:absolute;
    text-align: center;
}

form#registerForm button {
    margin-top:2.5em;
}

.formFieldExtra {
    position: absolute !important;
    top: -9999px !important;
    left: -9999px !important;
    visibility: hidden !important;
    /*    display:none !important;*/
}

.labelBlock {
    display:inline-block;
    vertical-align: middle;;
}

.dialogTextBox {
    position:relative;
    border-image-source: url('./images/BorderSmall.png');  
    border-image-repeat: stretch;
    border-image-slice: 32 fill;    
    box-sizing: border-box;
    border-image-width: 2em;
    padding: 1em 2em 2em 2em;
}

.dialogTextBoxCurseBreaker {
    position:relative;
    border-image-source: url('./images/BorderSmall_purple.png');  
    border-image-repeat: stretch;
    border-image-slice: 32 fill;    
    box-sizing: border-box;
    border-image-width: 2em;
    padding: 1em 2em 2em 2em;
}

.raffleImage
{
    content:url("./images/raffle_big.png");
    margin:1em 2em 1em 0;
    border:0;
    width:13em;
}

.raffleImageSeason1
{
    filter: none;
    -webkit-filter: hue-rotate(40deg);
    -moz-filter:    hue-rotate(40deg);
    -ms-filter:     hue-rotate(40deg);
    -o-filter:      hue-rotate(40deg);    
}

.raffleImageSeason2
{
    filter: none;
    -webkit-filter: hue-rotate(80deg);
    -moz-filter:    hue-rotate(80deg);
    -ms-filter:     hue-rotate(80deg);
    -o-filter:      hue-rotate(80deg);    
}

.raffleImageSeason3
{
    filter: none;
    -webkit-filter: hue-rotate(120deg);
    -moz-filter:    hue-rotate(120deg);
    -ms-filter:     hue-rotate(120deg);
    -o-filter:      hue-rotate(120deg);    
}

.raffleText
{
    text-align: center;
}

#raffleRowContainer
{
    justify-content: center;
}

.referralCode 
{
    font-size:1.1em;
    font-weight:bold;
    color:var(--item-name-color);
    cursor:pointer;
    word-break:break-all;
    overflow-wrap: break-word;
}
.referralImage
{
    content:url("./images/referral_link_v3.png");
    margin:1em 2em 1em 0;
    border:0;
    width:8em;
}

.whitelistNoticeImage
{
    margin:1em 0em 1em -2em;
}

.newsletterText
{
    text-align: center;
}

.newsletterLink
{
    word-break:break-all;
    overflow-wrap: break-word;
}

.newsletterImage
{
    content:url("./images/newsletter.png");
    margin:1em 2em 1em 0;
    border:0;
    width:8em;
}

.SubscribeButton
{
    width:10em;
}

.NFTOwnedText
{
    text-align: center;
}
.NFTOwnedImage
{
    content:url("./images/nftcollage.png");
    margin:1em 2em 1em 0;
    border:0;
    width:8em;
}
.NFTOwnedCount
{
    color:var(--item-name-color);
}

.clipboardImage
{
    content:url("./images/clipboard.png");
    margin:0em 0em 0em 0.5em;
    border:0;
    display:inline-block;
    height:1.5em;
    position:relative;
    top:0.4em;
}
.flexRowContainer
{
    display:flex;
    align-items:center;
}

.flexWrap
{
    display:flex;
    flex-wrap:wrap;
    gap:1em;
/*    padding: 2em; */
}

.flexItemFull
{
    flex:100%;
}
.flexItemHalf
{
    flex:1 1 0;
    min-width:30em;
}

.flexItemCarousel
{
    flex:1 1 0;
    min-width:30em;
}

.SliderContainer .flexItemCarousel
{
    flex:1 1 0;
    margin:1em;
    height:95%;
}


.dialogItemBack
{
    /*
    background: rgb(0,32,2);
    background: linear-gradient(0deg, rgba(0,32,2,1) 0%, rgba(0,56,3,1) 79%, rgba(1,84,5,1) 100%);
    */
}

.modalBack
{
    border-image-source: url('./images/BorderSmall.png');  
    border-image-repeat: stretch;
    border-image-slice: 32 fill;    
    box-sizing: border-box;
    border-image-width: 2em;
    padding: 1em 2em 2em 2em;
    /*background: rgb(38,18,0);
    background: linear-gradient(0deg, rgba(38,18,0,1) 0%, rgba(60,28,1,1) 62%, rgba(84,11,1,1) 100%);*/
}

.NFTFreeContainer
{
    display:flex;
    flex-wrap:wrap;
    width:100%;
}
.NFTViewer
{
    flex-basis:100%;
    padding-bottom:1em;
}

.NFTFreeHeader
{
    font-size:1.25em;
    text-align: center;
    width:100%;
}

.NFTVideoDiv 
{
    display:flex;
    flex-direction: row;
    gap:2em;    
}

.NFTVideoWrapper
{
    flex-basis: 40%;
    text-align: center;
}

.NFTDescription
{
    width: 100%;
    padding: 0 2em;
    box-sizing: border-box;
}

.NFTViewer .Collapsible
{
    margin-top:1em;
}

.Collapsible
{
  border: 2px solid var(--nft-name-border-color);
  width:100%;
  box-sizing: border-box;
  border-image:var(--green-gradient-border);
}

.Collapsible__trigger
{
    cursor: pointer;
    display: block;
    width: 100%;
    padding: .5em 1em;
    background-color: var(--nft-property-background-color);
    box-sizing: border-box;
    font-size: 1.1em;
    font-weight: bold;
}
.Collapsible__trigger.is-open::after
{
    background-image:url("./images/dropdown.png");
    background-size: .7em .5em;
    float:right;
    content:"";
    width: .7em;
    height: .5em;
    margin-top:.3em;
    transform: scaleY(-1);
    -webkit-tranform:scaleY(-1);
    -moz-tranform:scaleY(-1);
    -ms-tranform:scaleY(-1);
    -o-tranform:scaleY(-1);
}
.Collapsible__trigger.is-closed::after
{
    background-image:url("./images/dropdown.png");
    background-size: .7em .5em;
    float:right;
    content:"";
    width: .7em;
    height: .5em;
    margin-top:.3em;
}

.NFTVideoWrapper video, .NFTVideoWrapper img {
    width:100%;
}


.NFTImageContainer
{
    position:relative;
    top:0;
    left:0;
}

.NFTImage
{
    border:0;
}


.NFTProperties
{
    display:grid;
    gap:1em;    
    flex-basis: 60%;
    height:fit-content;
    grid-template-columns: repeat(auto-fill, minmax(10em, 1fr));
}

.NFTProperty
{
    border:2px solid var(--nft-property-border-color);
    border-radius:.5em;
    text-align:center;
    background-color:var(--nft-property-background-color);
    padding:.75em;
    min-width:fit-content;
}

.NFTPropertyLabel
{
    font-size:0.8em;
    font-weight:bold;
    color:var(--nft-property-label-color)
}
.NFTPropertyValue
{
    color:var(--nft-property-value-color);
    font-weight:bold;
}
.NFTPropertyPercent
{
    font-size:0.8em;
    color:var(--nft-property-percent-color);
}

.instructionsVideoWrapper
{
    width:28em;
    height:44em;
    position:relative;
}

.instructionsVideoWrapper video {
    position:absolute;
}

.instructionsPage .contentDiv
{
    flex-wrap:wrap;
}

.instructionsPage .headerText
{
    flex-basis:100%;
    margin:auto auto 0 auto;
}
.instructionsPage .contentFitDiv
{
    margin:0 auto auto auto;
}

.ModalDialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: auto;
    outline: none;
    padding: 1em;
  }
  
.ModalDialogFull {
    position: fixed;
    overflow: auto;
    outline: none;
    width:100%;
    height:100%;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
  
.ModalDialogOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(32, 32, 32, 0.85);
}

.ReactModal__Content--after-open {
    overflow-y: auto !important;
    max-height: 100%;
}

.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 600ms ease-in-out;
}

.ReactModal__Overlay--after-open{
    opacity: 1;
}

.ReactModal__Overlay--before-close{
    opacity: 0;
}

.ReactModalPortal
{
    z-index:50;
}

.modalButtonClose {
    padding: .5em 3em;
    color:#ffffff;
    background-color: #2a3b9a;
    border: 1px solid #ffffff;
    margin-left: 50%;
    margin-top:1em;
    margin-bottom:1em;
    transform:translate(-50%, 0);
    cursor:pointer;
}

.BackButton
{
    border-image-source: url("./images/store_buttonSM.png");
    border-image-slice: 34 96 43 74 fill;
    border-image-width: 1.2em 4em 1.5em 3em;
    background: #fff0;
    height: 4em;
    font-size: 1em;
    padding:0 4em .4em 4em;
    color: var(--button-text-color);
    cursor:pointer;
}
.BackButtonRed
{
    border-image-source: url("./images/store_buttonSM_red.png");
}
.ButtonText
{
    filter:drop-shadow(.11em .11em .05em #00000082);
    margin-left:-.5em;
}

.UserBar
{
    width: 100%;
    margin:0 auto;
}

.UserBarName {
    float:left;
    color:var(--item-name-color);    
    font-size:1.25em;
    font-weight:bold;
}
.UserBarNameLabel
{
    color:var(--text-color);
}

.UserBarPoints {
    float:right;
    color:var(--item-name-color);
    font-size:1.25em;
    font-weight:bold;
}

.UserBarPointsLabel{
    color:var(--text-color);
}

.UserBarPointsIcon
{
    content:url("./images/rewardPoints_32.png");
    margin:-1em .5em 0em 0.5em;
    border:0;
    display:inline-block;
    height:1.5em;
    position:relative;
    top:0.4em;
}

.AccountBox
{
}

.AccountHeader {
    font-size: 1.5em;
    font-weight: bold;
    text-align: center;
    width:100%;
    margin-bottom:1em;
    color:var(--text-color);
    font-family: 'Bolton';
}

.AccountField {
    color:var(--item-name-color);    
    font-weight:bold;
    padding:.5em 1em;
}
.AccountLabel
{
    color:var(--text-color);
}

.ButtonBackground
{
    border-image-source: url("./images/store_buttonSM.png");
    border-image-slice: 34 96 43 74 fill;
    border-image-width: 1.2em 4em 1.5em 3em;
    background: #fff0;
    height: 4em;
    font-size: 1em;
    padding:0 2em .4em 2em;
    color: var(--button-text-color);
    cursor:pointer;
}

.AccountButton
{
    width:100%;
    margin-top: 1em;
}

.AccountButton:disabled
{
    filter: none;
    -webkit-filter: grayscale(100%);
    -moz-filter:    grayscale(100%);
    -ms-filter:     grayscale(100%);
    -o-filter:      grayscale(100%);    
    cursor:default;
}

.RafflePageHeader
{
    font-size: 2em;
    font-weight:bold;
    text-align: center;
    margin-bottom: 1em;
    font-family:"Bolton";
}

.RaffleStorePageHeader
{
    font-size: 2em;
    font-weight:bold;
    text-align: center;
    margin-bottom: 1em;
    font-family:"Bolton";
}

.DailyRewardDialog
{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    margin: 1em;
    max-width: 100%;
}

.DailyRewardImageSmall
{
    content:url("./images/giftbox_small.png");
    margin:0 auto 1em;
    border:0;
    width:5em;
    display:none;
}

.DailyRewardImageContainer
{
}
.DailyRewardImage
{
    content:url("./images/giftbox.png");
    width: 30em;
    border:0;
    margin:0 auto;
}

.DailyRewardContainer
{
    display: flex;
    align-items: top;
    flex-wrap: wrap;
    justify-content: center;
    margin: 2em 0 0;
    grid-gap: 1.5em 1em;
    height:fit-content;
    min-width: 37em;
}

.RewardDayContainer
{
    display: flex;
    /*justify-content: center;*/
    align-items: center;
    border: 3px solid var(--daily-reward-border-color);
    border-radius: 1em;
    text-align: center;
    flex-direction: column;
    padding: 1em;
    position: relative;
}

.DailyRewardItem
{
}

.DailyItemLabel
{
    font-size: 1.2em;
    font-weight:bold;
    font-family: 'Bolton';
}
.DailyItemLabel.Highlight
{
    color:var(--daily-reward-label-highlight-color);
}

.DailyPointsImage
{
    content:url("./images/rewardPoints_256.png");
    margin:1em auto 0;
    border:0;
    width:3em;
}


.DailyCheck
{
    content: url("./images/checkmark.png");
    position: absolute;
    width:3em;
    top:-1.2em;
    right:-1.2em;
}

.DailyRaffleImage
{
    content:url("./images/raffle_big.png");
    margin:1em auto 0;
    border:0;
    width:4em;
}

.DailyConsecutiveDays
{
    margin-top: 1em;
    text-align: center;
}

.RewardDayDisabled
{
    filter: none;
    -webkit-filter: grayscale(100%) brightness(75%);
    -moz-filter:    grayscale(100%) brightness(75%);
    -ms-filter:     grayscale(100%) brightness(75%);
    -o-filter:      grayscale(100%) brightness(75%);    
}
.RewardDaySelected
{
    border: 3px dotted var(--daily-reward-border-highlight-color);
    background-color: var(--daily-reward-highlight-color);
    animation-name: DailySelectedAnim;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}

.ViewerOtherHeader
{
    width: 100%;
    text-align: center;
    font-size: 1.2em;
    font-weight: bold;
    margin-top: 1em;
    margin-bottom: 1em;}

.ViewerOtherContainer
{
    display:flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: normal;
    text-align: center;
    gap: 2em 2em;
    width: 100%;
    margin-bottom: 2em;
}

.ViewerOtherGroup
{
    display:flex;
    flex-direction: column;
    gap: 2em;
}

.ViewerOtherItem
{
}

.ViewerOtherImage
{
    width:20em;
    height:20em;
}

.ViewerOtherLabel
{
}

.PlayNowImage
{
    width:10em;
    margin-top: -1em;
}

.GameDemoImage
{
    content:url("./images/main_gamescreenshot_01.png");
    margin:1em 2em 1em 0;
    border:0;
    width:8em;
}

.CurseBreakerNew
{
    font-size:1.5em;
    color:var(--raffle-status-color);
    animation: Pulsate 1s linear infinite;
}

.CurseBreakerImage
{
    content:url("./images/cursebreaker.png");
    margin:1em 2em 1em 0;
    border:0;
    width:8em;
}

.ModalYes
{
    color:var(--button-text-color);
}
.ModalNo
{
    color:var(--button-text-color);
}
.ModalHighlight
{
    color:var(--item-name-color);
    overflow-wrap:anywhere;
}

.cs-message:not(:only-child) {
    margin: 1em 0 0 0;
      margin-left: 0px;
  }
  
@keyframes DailySelectedAnim {
    0% {
      background-color:var(--daily-reward-highlight-color);
    }
    50% {
      background-color:var(--daily-reward-highlight-color-darker);
    }
    100% {
      background-color:var(--daily-reward-highlight-color);
    }
}

.SliderContainer
{
    max-width: 1600px;
    width:100%;
    height:30em;
}

.ListContainer
{
    display:none;
}

.HomeBanner
{
    width:100%;
    max-width:1600px;
}

.HomeBanner img
{
    width:100%;
    border:none;
    cursor:pointer;
}


.FadeOut
{
    animation: fadeout .3s linear forwards;
}    
@keyframes fadeout {
    0% { opacity: 1; }
    100% { opacity: 0; }
}    
.FadeIn
{
    animation: fadein .3s linear forwards;
}
@keyframes fadein {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@media (max-width: 1600px) {
    .DailyRewardImageSmall
    {
        display:block;
    }
    .DailyRewardImageContainer
    {
        display:none;
    }
    #NFTPopup {
        min-width:95vw !important;
    }
}

@media screen and (max-width: 767px) {
    body {font-size:.5em}
    .dialog {
        max-width: 100vw;
    }
    input[type='text'],
    input[type='number'],
    input[type='password'],
    textarea {
        font-size: 2em !important;
    }    
}

@media screen and (max-aspect-ratio:1/1)
{
    .SliderContainer
    {
        display:none;
    }
    .ListContainer
    {
        display:inherit;
        flex-wrap:wrap;
        gap:1em;
    }
}
